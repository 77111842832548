/** @jsx jsx */
import { jsx, Flex, Box, Text, Grid } from 'theme-ui'
import { FC, ReactNode, useMemo, useState } from 'react'
import { Button } from '~/components/button'
import { CustomerOrder } from '~/@types/models'
import urls from '~/common/urls'
import { formatDate } from '~/utils/format'
import { Link } from '~/componentsV2/linkPrev/LinkPrev'
import MagnifierPaper from '../icons/magnifierPaper'
import Pagination from '~/components/paginator/Pagination'

// let PageSize = 5;

type RowProps = {
  name: string
  value: string | ReactNode
}

export const OrderStyleRow: FC<RowProps> = ({ name, value }) => (
  <Flex
    sx={{
      mb: [25],
    }}
  >
    <Box
      sx={{
        fontSize: [16],
        fontWeight: 700,
        color: '#000001',
      }}
      as={Text}
    >
      {name}
    </Box>
    <Box
      as={Text}
      sx={{
        fontSize: [16],
        flex: 1,
        textAlign: 'right',
        color: 'txt',
      }}
    >
      {value}
    </Box>
  </Flex>
)

type Props = {
  orders: CustomerOrder[]
  isOverview?: boolean
  PageSize?: number
}

const Mobile: FC<Props> = ({ orders = [], isOverview = false, PageSize = 5 }) => {
  const [currentPage, setCurrentPage] = useState(1)

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return orders.slice(firstPageIndex, lastPageIndex)
  }, [currentPage])
  return (
    <Box>
      {!isOverview && (
        <Flex>
          <h1
            sx={{
              fontFamily: 'Cormorant Garamond, Times New Roman, serif',
              fontWeight: 400,
              fontSize: '2.3rem',
              mt: [0],
              lineHeight: '48.44px',
              color: '#2b2b2b',
              mb: [36],
              marginRight: ['10px', '20px'],
            }}
          >
            ORDERS
          </h1>
        </Flex>
      )}

      {currentTableData.map(order => {
        const prefix = 'gid://shopify/Order/'
        const orderOnlyId = order.id.substring(prefix.length)
        // console.log(orderOnlyId)
        const orderEdges = order.lineItems.edges
        const orderItems: string[] = []
        orderEdges.map((element: any) => {
          return orderItems.push(element.node.title)
        })
        const countOfItems = order.lineItems.edges.length
        return (
          <Box
            sx={{
              mb: [48],
            }}
          >
            <Box
              sx={{
                mb: ['24px'],
              }}
            >
              <OrderStyleRow name="Order #" value={`#${order.orderNumber}`} />
              <OrderStyleRow name="Date" value={formatDate(order.processedAt)} />
              <OrderStyleRow
                name="Items"
                value={
                  <Box as="span" variant="firstLetterToUppercase">
                    {orderItems.join(' | ')}
                  </Box>
                }
              />
              <OrderStyleRow name="Total" value={`$ ${order.totalPrice.amount}`} />
              <OrderStyleRow
                name="Status"
                value={
                  <Box as="span" variant="firstLetterToUppercase">
                    {String(order.fulfillmentStatus)}
                  </Box>
                }
              />
            </Box>
            <Link
              sx={{
                textDecoration: 'none',
                color: '#000001',
                fontWeight: 700,
              }}
              to={`${urls.account.accountOrders}/${orderOnlyId}`}
            >
              <Box
                sx={{
                  padding: [15],
                  border: 'solid 1px #000001',
                  textAlign: 'center',
                  cursor: 'pointer',
                  display: 'flex', // Use flexbox for centering
                  alignItems: 'center', // Vertically center content
                  justifyContent: 'center', // Horizontally center content
                }}
              >
                <span
                  sx={{
                    mr: [10],
                  }}
                >
                  ORDER DETAIL
                </span>
              </Box>
            </Link>
          </Box>
        )
      })}
      {currentTableData.length === 0 && (
        <h3 sx={{ textAlign: 'center' }}>There are currently no orders</h3>
      )}
      {!isOverview && (
        <Grid
          columns={[1]}
          sx={{
            borderTop: t => `1px solid #E6E6E6`,
            justifyItems: 'center',
            pt: [16],
          }}
        >
          <span sx={{ fontSize: '14px', mb: [30] }}>
            Showing {currentTableData.length} items of {orders.length}
          </span>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={orders.length}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
          />
        </Grid>
      )}
    </Box>
  )
}

export default Mobile

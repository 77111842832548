/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { FC } from 'react'
import urls from '~/common/urls'
import {
  DashboardEmptyState,
  DashboardGrid,
  DashboardProjectCard,
} from '~/components/account-layout/components/sections'
import { useDashboard } from '../context/dashboardContext'

type Props = {
  cardProps: {
    showMenu?: boolean
    style?: React.CSSProperties
  }
}

export const DashboardProjectGrid: FC<Props> = ({ cardProps }) => {
  const { hasNoWishlists, listsArray, loading } = useDashboard()

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <p>Loading...</p>
      </Box>
    )
  }

  if (!listsArray?.length) return <DashboardEmptyState>Currently no projects</DashboardEmptyState>

  return (
    <DashboardGrid>
      {!loading &&
        !hasNoWishlists &&
        listsArray?.map((list, index) => {
          return (
            <DashboardProjectCard
              key={list.lid}
              list={list}
              link={`${urls.account.accountPortfolios}/${list.lid}`}
              {...cardProps}
            />
          )
        })}
    </DashboardGrid>
  )
}
